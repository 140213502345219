import { I18n } from '@front/volcanion'

class Callbacks {
  static resetPasswordHandler(openNotification, handleResetPasswordAction) {
    return async function resetPassword(state, extra) {
      const { method, user_id, application } = state
      try {
        await handleResetPasswordAction({ user_id, channel: 'customer', adapter: method, application, user_type: 'booker' })
        openNotification(I18n.t('password.reset.success', { method }), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('password.reset.error', { method }), { variant: 'error' })
      }
    }
  }

  static sendWelcomeEmailHandler(openNotification, handleWelcomeAction) {
    return async function sendWelcomeEmail(state, extra,) {
      const { user_id } = state
      try {
        await handleWelcomeAction({ user_id, channel: 'customer', adapter: 'email' })
        openNotification(I18n.t('email.welcome.success'), { variant: 'success' })
      } catch (err) {
        openNotification(I18n.t('email.welcome.error'), { variant: 'error' })
      }
    }
  }

}


export default Callbacks
