import React, { useEffect } from "react"

import { hooks } from "@front/volcanion"
import { HookUtils } from "@front/volcanion/utils"
import { Loader } from "@front/squirtle"

import NoResultComponent from "../noResult"

const withContainer = Component => props => {
  const [{ company }] = hooks.useFormState()
  const [contract, service, user, date_start, date_end] = hooks.useFieldValues(['contract', 'service', 'user', 'date_start', 'date_end'])

  const [data, search, state] = hooks.useModelValues('companyuser', 'get', {})
  const [dataHistory, searchHistory, stateHistory] = hooks.useModelValues('companyuser', 'get', {})

  const filter = {
    createdAt: { '>=': date_start, '<=': date_end },
    company,
    services: !!service && [service] || !!contract && { contract: { companycontract_id: contract } } || undefined
  }

  const filterHistory = {
    createdAt: { '<': date_start },
    company,
    services: !!service && [service] || !!contract && { contract: { companycontract_id: contract } } || undefined
  }

  const options = {
    group_by: ['DATE(createdAt) AS x'],
    no_select: true,
    query_operations: [{ path: 'companyuser_id', operation: 'COUNT', alias: 'y' }],
    sort: ['DATE(createdAt) ASC'],
    select: [],
    log_query: true,
    limit: -1,
    type: 'b2b'
  }

  const optionsHistory = {
    no_select: true,
    query_operations: [{ path: 'companyuser_id', operation: 'COUNT', alias: 'originValue' }],
    sort: ['DATE(createdAt) ASC'],
    select: [],
    single: true,
    type: 'b2b'
  }

  useEffect(() => {
    search({ filter, options })
  }, [contract, service, user, date_start, date_end])


  useEffect(() => {
    searchHistory({ filter: filterHistory, options: optionsHistory })
  }, [contract, service, user, date_start])

  const cumulativeData = _.reduce(data, (acc, curr, index) => {
    const prevValue = index > 0 ? acc[index - 1].y : dataHistory?.originValue
    acc.push({ x: curr.x, y: prevValue + curr.y })
    return acc
  }, [])

  const mergedProps = {
    data: cumulativeData,
    date_start: date_start,
    date_end: date_end,
    originValue: dataHistory?.originValue
  }

  return (
    <Loader isLoading={HookUtils.getLoadingState([state, stateHistory])} size={100} >
      {_.isEmpty(data) ? <NoResultComponent /> : <Component {...mergedProps} />}
    </Loader>
  )
}

export default withContainer
